import React, { Fragment } from 'react';

function Found() {
  return <Fragment>&nbsp;&nbsp;contacts available in Xero to download</Fragment>;
}

function Looking() {
  return (
    <Fragment>
      <h4>Looking for contacts ...</h4>
    </Fragment>
  );
}


function ShowFetchData() {
  return (
    <Fragment>
      <h4 style={{ color: '#1674ba' }}>Ready to fetch your contacts!</h4>
    </Fragment>
  );
}


function NotConnected() {
  return (
    <Fragment>
      <h4 style={{ color: '#d9534f' }}>You are not connected to Xero</h4>
      <p className='connMessage'>Exporting Contacts requires a connection to Xero.</p>
      <p className='connMessage'>Please click <a href='/xero'>here</a> to connect to Xero first and try again.</p>
      <p className='connMessage'>Alternatively, you can navigate to the page via the side Menu &gt; Config &gt; Connect Xero.</p>
    </Fragment>
  );
}


export { NotConnected, ShowFetchData, Found, Looking };

