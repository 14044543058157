import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router";
import xeroConnected from "../../img/xeroConnected.gif";
import "./animate.css";

class Xero extends Component {
  async componentDidMount() {

    // THE BELOW VARIABLES ARE EXPLAINED HERE:
    // https://github.com/XeroAPI/xero-node/tree/master

    // XERO GIVES US OUR TOEKEN SET IN THE URL PASS BACK
    const TokenSet = await axios.post("/callback", window.location.href);
    // SET XERO EXPIRY IN MILLISECONS (WE CHECK ON THIS LATER IN ORDER TO CLEAR THE ORGS DROP DOWN TO SHOW THE USER THEY ARE NOT CONNECTED)
    localStorage.setItem('xero_expiry', JSON.stringify(TokenSet.data.tokenSet.expires_at));
    // SET XERO REFRESH TOKEN. WE USE THIS AGAINST THE SDK WHEN MAKING XERO CALLS
    localStorage.setItem('xrt', TokenSet.data.tokenSet.refresh_token);
    // SET TENANTS - THIS IS THE LIST OF XERO ORGANIZATIONS THE USER IS CONNECTED TO
    localStorage.setItem('tenants', JSON.stringify(TokenSet.data.tenants));

    localStorage.setItem('tokenSet', JSON.stringify(TokenSet.data));
    

    // UPDATE
    let profile = await axios.get("/api/profile");
    let update = { ...profile.data, "xeroAuth":TokenSet.data };
    await axios.post("/api/profile", update);

    // ONCE WE HAVE ALL OF OUR DATA WE CAN RE-DIRECT TO THE DASHBOARD.
    // "window.location.replace" WILL ALSO CAUSE A REFRESH OF THE NAVBAR WHICH WILL ALLOW THE USE TO CHOOSE A XERO ORG
    this.redirectTimeout = setTimeout(() => {
      window.location.replace('/dashboard')
    }, 2000);
  }

  render() {
    return (
      <div style={{ textAlign: "center", top: "200px", position: "relative" }}>
        <h1 style={{ color: "#5c5969" }}>Connected!</h1>
        <img
          style={{ width: "100px", height: "100px", marginTop: "2em" }}
          src={xeroConnected}
          alt="connected to xero"
        />
      </div>
    );
  }
}

export default withRouter(Xero);
