import axios from "axios";
const onPostClickXero = async function onPostClickXero(purchases) {
  try {
    // Obtain Xero keys from profile to send with invoice data
    let tokenSet = await localStorage.getItem('tokenSet');
    let refresh = await localStorage.getItem('xrt');
    let currentTenant = await localStorage.getItem('currentOrg');
    let data = {...JSON.parse(tokenSet), ...JSON.parse(currentTenant), refresh}

    // IF NOT CURRENT ORG THEN THERE IS NO ACTIVE CONNECTION. SEND ERROR TO RECONNECT
    if (localStorage.getItem("currentOrg") === 0) {
      return "500";
    }

    //let data = { ...tokenSet, ...JSON.parse(currentTenant), refresh };
    let toPost = { purchases, data };
    // Send invoice data
    let res = await axios.post("/api/post-purchases", toPost);
    return res.data;
  } catch (e) {
    console.log(e);
    return "500";
  }
};

export default onPostClickXero;
