import PropTypes from "prop-types";
import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  deletePurchases,
  getPurchases,
  postPurchases,
  updateStatus,
} from "../../actions/redux/purchaseActions";
import postWorkbook from "../../actions/workbook/post/purchases";
import postXero from "../../actions/xero/purchases";
import Spinner from "../common/Spinner";
import HeadingText from "./Headers";
import ErrorItems from "./Errors";
import PurchaseItem from "./PurchaseItem";
import WorkbookButton from "./buttons/WorkbookButton";
import XeroButton from "./buttons/XeroButton";

class Purchases extends Component {
  // INIT DEFAULT STATE. THESE VALUES WILL CHANGE.
  state = {
    exported: false,
    exportingToXero: false,
    postingToWorkbook: false,
    postedToWorkbook: false,
    showErrors: false,
    showButtons: true,
    showHeading: true,
    errors: [],
    loading: false,
    xero500: false,
    modalShow: false,
  };

  // componentDidMount IS A REACT LIFECYCLE COMPONANT. WHEN REACT SEE'S THIS METHOD IT WILL RUN
  // IT BEFORE ANYTHING ELSE. IN THIS CASE THE APPLICATION MUST FETCH DATA BEFORE ANYTHING ELSE
  // https://reactjs.org/docs/react-component.html#componentdidmount
  async componentDidMount() {
    let response = await this.props.getPurchases();
  }

  // THREE FUNCTIONS BELOW ARE CALLED WHEN UPDATES ARE APPLIED TO RADIO BUTTONS OR THE ERROR MODAL
  handleDraftChange = () => {
    this.props.updateStatus("DRAFT");
  };

  handleAcceptedChange = () => {
    this.props.updateStatus("AUTHORISED");
  };

  handleModalShow = () => {
    this.setState({ modalShow: true });
  };

  // FUNCTION IS RAN WHEN 'POST TO WORKBOOK' BUTTON IS CLICKED.
  // ALL DATA IN STATE ARE SENT TO GET UPDATED IN WORKBOOK
  // STATE IS UPDATED AS NEEDED TO DISPLAY/HIDE ITEMS ON THE PAGE
  onPostClickWorkbook = async (purchases) => {
    this.setState({ loading: true, postingToWorkbook: true });
    let response = await postWorkbook(purchases);
    this.setState({
      loading: false,
      showErrors: false,
      exported: true,
      postingToWorkbook: false,
      postedToWorkbook: true,
    });
    this.props.deletePurchases();
    return response;
  };

  // ********************************************************
  // *** EXPORT TO XERO ***
  // EXPORT TO XERO FUNCTION RUN WHEN XERO BUTTON IS CLICKED
  // ********************************************************
  onPostClickXero = async () => {
    // PASS IN THE INVOICES ARRAY WHICH NEEDS TO BE SENT TO XERO
    const purchases = this.props.purchases;
    // SET LOADING AND EXPORTING TO XERO TO SHOW THE SPINNED WHILE AWAITING RESULT FROM XERO
    this.setState({ loading: true, exportingToXero: true });
    let response = await postXero(purchases);
    // ONCE REPONSE IS RETURNED REVERESE LOADING AND EXPORTING TO XERO STATES
    this.setState({ loading: false, exportingToXero: false });

    // IF RESPONSE IS 500 UPDATE STATE WHICH WILL DISPLAY THERE WAS AN ERROR
    // THIS MEANS THERE WAS EITHER NO RESPONSE OR THE CURRENT XERO ORG IS NOT DSIPLAYED
    if (response == "500") {
      this.setState({
        exported: false,
        xero500: true,
      });
      return;
    }

    // IF RESPONSE STATUS IS 500 UPDATE STATE WHICH WILL DISPLAY THERE WAS AN ERROR
    // THIS MEANS CONNECTION TO XERO WAS MADE BUT THE DATA STRUCTURE WAS WRONG OR XERO REFUSED CONNECTION
    if (response.body.Status === 500) {
      this.setState({
        exported: false,
        xero500: true,
      });
    }

    // IF RESPONSE STATUS IS 400 AND ERROR NUMBER WAS 10 UPDATE STATE WHICH WILL DISPLAY THERE WAS ISSUES
    // THIS MEANS A SUCCESSFULL CONNECTION TO XERO WAS MADE BUT THERE WAS AN ISSUE WITH THE DATA SUCH AS DUPLICATED INVOICE NUMBER
    if (
      response.response.statusCode == 400 ||
      response.response.body.ErrorNumber == 10
    ) {
      this.setState({
        exported: true,
        xero500: false,
        showErrors: true,
        errors: response.response.body.Elements.map((i) => {
          var errors = "";
          var warnings = "";
          if (
            i.Warnings !== undefined
              ? (warnings = i.Warnings.map((i) => i.Message))
              : (warnings = "")
          );
          if (
            i.ValidationErrors !== undefined
              ? (errors = i.ValidationErrors.map((i) => i.Message))
              : (errors = "")
          );
          return {
            purchase: i.PurchaseOrderNumber,
            warnings: warnings,
            errors: errors,
          };
        }).filter((i) => i.errors || i.warnings),
      });
    }

    // IF RESPONSE STATUS IS 200 AND NO WARNINGS EXIST
    // THIS MEANS A SUCCESSFULL CONNECTION TO XERO. NO ERRORS OR WARNINGS - EVERYTHNING WENT FINE
    // UPDATE STATE TO EXPORTED BUT DONT UPDATE STATE FOR ERRORS
    if (
      response.response.statusCode === 200 &&
      response.response.body.PurchaseOrders.map((i) => i.Warnings === undefined).pop(
        "true"
      )
    ) {
      this.setState({
        exported: true,
      });
    }

    // IF RESPONSE STATUS IS 200 BUT WARNINGS EXIST
    // THIS MEANS A SUCCESSFULL CONNECTION TO XERO. NO ERRORS, SO DATA WAS SENT TO XERO BUT THERE WERE WARNINGS
    // UPDATE STATE TO EXPORTED BUT DONT UPDATE STATE FOR ERRORS - DO HOWEVER SHOW MODAL TO DISPLAY WARNINGS
    // THIS GIVES USER OPTIONS TO DECIDE IF THEY WANT TO GO INTO XERO AND REMOVE THE INVOICES AND TRY AGAIN
    if (
      response.response.statusCode === 200 &&
      response.response.body.Type === undefined &&
      !response.response.body.PurchaseOrders.map((i) => i.Warnings === undefined).pop(
        "true"
      )
    ) {
      const returnedErrors = response.response.body.PurchaseOrders.map((i) => {
        return {
          PurchaseNumber: i.PurchaseOrderNumber,
          ValidationErrors: i.ValidationErrors,
          Warnings: i.Warnings,
        };
      });
      this.setState({
        exported: true,
        xero500: false,
        modalShow: true,
        errors: returnedErrors
          .map((i) => {
            var warnings = "";
            if (
              i.Warnings !== undefined
                ? (warnings = i.Warnings.map((i) => i.Message))
                : (warnings = "")
            );
            return {
              purchase: i.PurchaseOrderNumber,
              warnings: warnings,
            };
          })
          .filter((i) => i.errors || i.warnings),
      });
    }

    // RETURN RESPONSE FROM ONE OF THE 4 OPTIONS ABOVE
    // THIS RESPONSE WILL CONTAIN THE VALUES TO UPDATE THE STATE AND MAPPED ERRORS
    return response;
  };

  // SET LISTENER TO WATCH FOR CHANGES. THIS NEEDS TO WATCH TO SEE IF XERO RETURNS
  // AN ERROR SO THE BUTTONS WILL BE HIDDEN
  // https://developmentarc.gitbooks.io/react-indepth/content/life_cycle/update/component_will_receive_props.html
  componentWillReceiveProps(nextProps) {
    if (this.props.purchase === 9 || nextProps.purchase === 9) {
      this.setState({ showButtons: false });
    }
  }

  // **************************************************
  // RENDER METHOD CONSTRUCTS THE OUTPUT TO THE PAGE
  // https://reactjs.org/docs/rendering-elements.html
  // **************************************************
  render() {
    // DEFINE VARIABLES FROM PROPS
    const { purchases, loading } = this.props.purchase;
    // DEFINE VARIABLES FROM STATE
    const {
      errors,
      showErrors,
      exported,
      showButtons,
      showHeading,
      xero500,
      postingToWorkbook,
      exportingToXero,
      postedToWorkbook,
    } = this.state;
    let purchaseItems;
    let errorItems;

    // HANDLE MODAL CLOSE
    // WHEN THIS fUNCTION IS CALLED THE MODULE IS CLOSED VIA STATE
    let modalClose = () => this.setState({ modalShow: false });

    // IF THE STATE LOADINGS == TRUE, OVER RIDE THE INVOICE ITEMS WITH A SPINNER TO SIGNIFY IT IS LOADING
    if (purchases === null || loading || this.state.loading) {
      purchaseItems = <Spinner animation="border" variant="primary" />;
    }

    // If WorkBook returns purchases, push purchase response to the purchaseItems <div />
    else {
      // ITEMS ARE WRAPPED WITH COMPONENTS FROM THE react-transition-group LIBRARY 
      // THIS WILL ANIMATE EACH ITEM WHEN REMOVED FOR BETTER VISUAL FEEDBACK
      // https://reactcommunity.org/react-transition-group/
      if (purchases !== 9 && purchases !== undefined) {
        purchaseItems = (
          <TransitionGroup> {
            purchases.map((purchase) => (
              <CSSTransition
                key={purchase.Id}
                timeout={200}
                classNames="itemsAnimate"
              >
                <PurchaseItem key={purchase.Id} purchase={purchase} />
              </CSSTransition>
            ))
          }
          </TransitionGroup>
        )
      }

      if (errors.length !== 0) {
        errorItems = errors.map((error) => (
          <ErrorItems
            key={error.purchase}
            show={this.state.modalShow}
            onHide={modalClose}
            errors={errors}
          />
        ));
      }
    }

    // RETURN THE JSX TO THE BROWSER DOM
    // https://reactjs.org/docs/introducing-jsx.html
    return (
      // USE REACT-BOOTSTRAP TO CREATE A GRID SYSTEM FOR JSX/HTML
      // https://react-bootstrap.github.io/layout/grid/
      <Container>
        {showHeading === true ? (
          <Row className="d-flex flex-column headingText">
            <HeadingText
              loading={loading}
              exported={exported}
              exportingToXero={exportingToXero}
              postingToWorkbook={postingToWorkbook}
              purchases={purchases}
              showErrors={showErrors}
              xero500={xero500}
              postedToWorkbook={postedToWorkbook}
            />
          </Row>
        ) : null}

        {/* 
        // IMPORT THE XERO AND WORKBOOK BUTTONS AND DISPLAY THEM BASED ON THE
        // STATES BELOW

        // PROPS ARE PASSED INTO EACH BUTTON COMPONANT TO DETERMINE THE BUTTON STYLE
        // EG - > exported={exported} PASSED THE EXPORTED STATE INTO THE BUTTON COMPONANT
        // THIS ALLOWS THE BUTTON COMPONANT TO DO IF/ELSE BASED ON IF exported=TRUE/FALSE
        */}
        {purchases !== 9 &&
          purchaseItems.length !== 0 &&
          showButtons &&
          !xero500 &&
          !this.state.loading ? (
          <Row className="justify-content-sm-left paymentButton">
            <Col className="xeroButton">
              <XeroButton
                loading={loading}
                exported={exported}
                showErrors={showErrors}
                errors={errors}
                purchases={purchases}
                onPostClickXero={this.onPostClickXero}
                handleModalShow={this.handleModalShow}
              />
            </Col>
            <Col className="workbookButton">
              <WorkbookButton
                loading={loading}
                exported={exported}
                showErrors={showErrors}
                purchases={purchases}
                onPostClickWorkbook={this.onPostClickWorkbook}
              />
            </Col>
          </Row>
        ) : null}

        {/* 
        // DISPLAY RADIO BUTTONS TO UPDATE THE STATUS OF ALL DATA BETWEEN DRAFT AND ACCEPTED
        // XERO READS IN THIS FIELD WHEN THE DATA IS EXPORTED TO DETERMINE ITS STATE WITHIN XERO
        // RADIO BUTTONS ARE ONLY DISPLAYED IF ALL CASES OF THE CURRENT STATE BELOW ARE MATCHED
        */}
        {purchases !== 9 &&
          purchaseItems.length !== 0 &&
          !exported &&
          showButtons &&
          !xero500 &&
          !loading &&
          !this.state.loading ? (
          <Row className="d-flex flex-column radioContainer">
            <Col xs={6}>
              Export as:
              <input
                type="radio"
                value={this.props.purchase.Status}
                defaultChecked
                name="status"
                onChange={this.handleDraftChange}
              />
              Draft
              <input
                type="radio"
                value={this.props.purchase.Status}
                name="status"
                onChange={this.handleAcceptedChange}
              />
              Authorised
            </Col>
          </Row>
        ) : null}

        {/* 
        // IF SHOW ERRORS STATE IS TRUE, DISPLAY ERRORS CONTAINER
        // THIS WILL BE SHOWN WITHIN A MODAL
        */}
        {showErrors ? (
          <Row className="d-flex flex-column errorsContainer">{errorItems}</Row>
        ) : null}
        {/* 
        // IF NO ERRORS STATE IS TRUE, DISPLAY DATA ITEMS
        */}
        {!xero500 ? (
          <Row className="d-flex flex-column">

            {purchaseItems}

          </Row>
        ) : null
        }
      </Container>
    );
  }
}

// TYPE CHECKING FOR DATA IN REACT. HELPS TOOLING TO FIGURE OUT ISSUES
// https://www.npmjs.com/package/prop-types
Purchases.propTypes = {
  deletePurchases: PropTypes.func,
  updateStatus: PropTypes.func,
  getPurchases: PropTypes.func.isRequired,
  purchase: PropTypes.object.isRequired,
  errors: PropTypes.object,
  postPurchases: PropTypes.func,
};

// THIS FUNCTION SELECTS PARTS OF THE REDUX STATE AND PASSES IT IN AS PROPS
// TO THE COMPONENT THAT REDUX CONNECT() IS APPLIED TO.
// https://react-redux.js.org/using-react-redux/connect-mapstate
const mapStateToProps = (state) => ({
  purchase: state.purchases,
  purchases: state.purchases.purchases,
  profile: state.profile,
  errors: state.errors,
});

// DISPATCHES METHODS IN THIS COMPONANT TO UPDATE THE GLOBAL STORE (REDUX STATE)
// https://react-redux.js.org/using-react-redux/connect-MAPDISPATCH
const mapDispatchToProps = (dispatch) => {
  return {
    postPurchases: (purchases) => dispatch(postPurchases(purchases)),
    updateStatus: (purchases) => dispatch(updateStatus(purchases)),
    getPurchases: (purchases) => dispatch(getPurchases(purchases)),
    deletePurchases: (purchases) => dispatch(deletePurchases(purchases))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Purchases);
