import format from "date-fns/format";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  deleteCreditNote,
  updateSingleStatus
} from "../../actions/redux/creditNoteActions";

class CreditNoteItem extends Component {
  state = {
    showCreditNoteInfo: false,
    isDraftChecked: "checked",
    isAuthorisedChecked: ""
  };

  onDeleteClick(e) {
    this.props.deleteCreditNote(this.props.creditNote.Id);
  }

  handleDraftChange = () => {
    this.props.updateSingleStatus(this.props.creditNote.Id, "DRAFT");
    this.setState({
      isDraftChecked: "checked",
      isAuthorisedChecked: ""
    });
  };

  handleAcceptedChange = () => {
    this.props.updateSingleStatus(this.props.creditNote.Id, "AUTHORISED");
    this.setState({
      isDraftChecked: "",
      isAuthorisedChecked: "checked"
    });
  };

  componentWillReceiveProps() {
    if (this.props.creditNote.Status === "DRAFT") {
      return this.setState({
        isDraftChecked: "checked",
        isAuthorisedChecked: ""
      });
    }
    if (this.props.creditNote.Status === "AUTHORISED") {
      return this.setState({
        isDraftChecked: "",
        isAuthorisedChecked: "checked"
      });
    }
  }

  render() {
    const { creditNote } = this.props;
    const { showCreditNoteInfo, isDraftChecked, isAuthorisedChecked } = this.state;
    return (
      <Wrapper>
        <div className="card card-header bg-light">
          <div>
            <div className="radio-status item-radio">
              <input
                type="radio"
                value="DRAFT"
                checked={isDraftChecked}
                name={creditNote.Id}
                onChange={this.handleDraftChange.bind(this)}
              />
              Draft
              <input
                type="radio"
                value="AUTHORISED"
                checked={isAuthorisedChecked}
                name={creditNote.Id}
                onChange={this.handleAcceptedChange.bind(this)}
              />
              Authorised
            </div>
            <div
              className="item-delete"
              style={{
                textAlign: "right",
                color: "red",
                cursor: "pointer"
              }}
            >
              <i
                className="fa fa-times"
                aria-hidden="true"
                onClick={this.onDeleteClick.bind(this)}
              />
            </div>
          </div>

          <ul className="list-group-items list-group  list-group-flush">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Credit Note</th>
                    <th style={{ width: "25%" }} scope="col">
                      Client
                    </th>
                    <th style={{ width: "35%" }} scope="col">
                      Reference
                    </th>
                    <th scope="col">Total ({creditNote.CurrencyCode})</th>
                    <th scope="col">Issued</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{creditNote.InvoiceNumber}</td>
                    <td>{creditNote.Contact.Name}</td>
                    <td>{creditNote.Reference}</td>
                    <td>${creditNote.Total}</td>
                    <td>{format(new Date(creditNote.Date), "DD/MM/YYYY")}</td>
                  </tr>
                </tbody>
              </table>
              <Button
                className="lineItemsButton"
                onClick={() =>
                  this.setState({ showCreditNoteInfo: !showCreditNoteInfo })
                }
                variant="primary"
                size="sm"
                aria-controls="line-items"
                aria-expanded={showCreditNoteInfo}
                style={{ marginBottom: "1em", marginTop: "1em" }}
              >
                Line Items
              </Button>
              <Collapse in={this.state.showCreditNoteInfo}>
                <div id="line-items">
                  <ul className="list-group">
                    <table className="table table-striped">
                      <thead className="thead-dark">
                        <tr>
                          <th style={{ width: "45%" }} scope="col">
                            Description
                          </th>
                          <th style={{ width: "10%" }} scope="col">
                            Qty
                          </th>
                          <th style={{ width: "15%" }} scope="col">
                            Unit $
                          </th>
                          <th style={{ width: "15%" }} scope="col">
                            Tax $
                          </th>
                          <th style={{ width: "15%" }} scope="col">
                            Acc Code
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {creditNote.LineItems.map(item => {
                          return (
                            <tr key={item.Id}>
                              <td>{item.Description}</td>
                              <td>{item.Quantity}</td>
                              <td>${item.UnitAmount}</td>
                              <td>${item.TaxAmount}</td>
                              <td>{item.AccountCode}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </ul>
                </div>
              </Collapse>
            </div>
          </ul>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: auto;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px 2px;
  textshadow: 0px 5px 15px rgba(255, 255, 255, 0.5);
  margin-bottom: 1em;
`;

CreditNoteItem.propTypes = {
  creditNote: PropTypes.object.isRequired,
  deleteCreditNote: PropTypes.func,
  updateSingleStatus: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    deleteCreditNote: creditNote => dispatch(deleteCreditNote(creditNote)),
    updateSingleStatus: creditNote => dispatch(updateSingleStatus(creditNote))
  };
};

export default connect(mapDispatchToProps, {
  deleteCreditNote,
  updateSingleStatus
})(CreditNoteItem);
