import format from "date-fns/format";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  deleteCreditorInvoice,
  updateSingleStatus
} from "../../actions/redux/creditorInvoiceActions";

class CreditorInvoiceItem extends Component {
  state = {
    showCreditorInvoiceInfo: false,
    isDraftChecked: "checked",
    isAuthorisedChecked: ""
  };

  onDeleteClick(e) {
    this.props.deleteCreditorInvoice(this.props.creditorInvoice.Id);
  }

  handleDraftChange = () => {
    this.props.updateSingleStatus(this.props.creditorInvoice.Id, "DRAFT");
    this.setState({
      isDraftChecked: "checked",
      isAuthorisedChecked: ""
    });
  };

  handleAcceptedChange = () => {
    this.props.updateSingleStatus(this.props.creditorInvoice.Id, "AUTHORISED");
    this.setState({
      isDraftChecked: "",
      isAuthorisedChecked: "checked"
    });
  };

  componentWillReceiveProps() {
    if (this.props.creditorInvoice.Status === "DRAFT") {
      return this.setState({
        isDraftChecked: "checked",
        isAuthorisedChecked: ""
      });
    }
    if (this.props.creditorInvoice.Status === "AUTHORISED") {
      return this.setState({
        isDraftChecked: "",
        isAuthorisedChecked: "checked"
      });
    }
  }

  render() {
    const { creditorInvoice } = this.props;
    const { showCreditorInvoiceInfo, isDraftChecked, isAuthorisedChecked } = this.state;
    return (
      <Wrapper>
        <div className="card card-header bg-light">
          <div>
            <div className="radio-status item-radio">
              <input
                type="radio"
                value="DRAFT"
                checked={isDraftChecked}
                name={creditorInvoice.Id}
                onChange={this.handleDraftChange.bind(this)}
              />
              Draft
              <input
                type="radio"
                value="AUTHORISED"
                checked={isAuthorisedChecked}
                name={creditorInvoice.Id}
                onChange={this.handleAcceptedChange.bind(this)}
              />
              Authorised
            </div>
            <div
              className="item-delete"
              style={{
                textAlign: "right",
                color: "red",
                cursor: "pointer"
              }}
            >
              <i
                className="fa fa-times"
                aria-hidden="true"
                onClick={this.onDeleteClick.bind(this)}
              />
            </div>
          </div>

          <ul className="list-group-items list-group  list-group-flush">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Creditor Invoice</th>
                    <th style={{ width: "25%" }} scope="col">
                      Creditor
                    </th>
                    <th style={{ width: "35%" }} scope="col">
                      Reference
                    </th>
                    <th scope="col">Total ({creditorInvoice.CurrencyCode})</th>
                    <th scope="col">Issued</th>
                    <th scope="col">Due</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{creditorInvoice.InvoiceNumber}</td>
                    <td>{creditorInvoice.Contact.Name}</td>
                    <td>{creditorInvoice.Reference}</td>
                    <td>${creditorInvoice.Total}</td>
                    <td>{format(new Date(creditorInvoice.Date), "DD/MM/YYYY")}</td>
                    <td>{format(new Date(creditorInvoice.DueDate), "DD/MM/YYYY")}</td>
                  </tr>
                </tbody>
              </table>
              <Button
                className="lineItemsButton"
                onClick={() =>
                  this.setState({ showCreditorInvoiceInfo: !showCreditorInvoiceInfo })
                }
                variant="primary"
                size="sm"
                aria-controls="line-items"
                aria-expanded={showCreditorInvoiceInfo}
                style={{ marginBottom: "1em", marginTop: "1em" }}
              >
                Line Items
              </Button>
              <Collapse in={this.state.showCreditorInvoiceInfo}>
                <div id="line-items">
                  <ul className="list-group">
                    <table className="table table-striped">
                      <thead className="thead-dark">
                        <tr>
                          <th style={{ width: "45%" }} scope="col">
                            Description
                          </th>
                          <th style={{ width: "10%" }} scope="col">
                            Qty
                          </th>
                          <th style={{ width: "15%" }} scope="col">
                            Unit $
                          </th>
                          <th style={{ width: "15%" }} scope="col">
                            Tax $
                          </th>
                          <th style={{ width: "15%" }} scope="col">
                            Acc Code
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {creditorInvoice.LineItems.map(item => {
                          return (
                            <tr key={item.Id}>
                              <td>{item.Description}</td>
                              <td>{item.Quantity}</td>
                              <td>${item.UnitAmount}</td>
                              <td>${item.TaxAmount}</td>
                              <td>{item.AccountCode}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </ul>
                </div>
              </Collapse>
            </div>
          </ul>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: auto;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px 2px;
  textshadow: 0px 5px 15px rgba(255, 255, 255, 0.5);
  margin-bottom: 1em;
`;

CreditorInvoiceItem.propTypes = {
  creditorInvoice: PropTypes.object.isRequired,
  deleteCreditorInvoice: PropTypes.func,
  updateSingleStatus: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    deleteCreditorInvoice: creditorInvoice => dispatch(deleteCreditorInvoice(creditorInvoice)),
    updateSingleStatus: creditorInvoice => dispatch(updateSingleStatus(creditorInvoice))
  };
};

export default connect(mapDispatchToProps, {
  deleteCreditorInvoice,
  updateSingleStatus
})(CreditorInvoiceItem);
