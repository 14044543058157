import React, { Fragment } from "react";

const WorkbookButton = ({
  loading,
  exported,
  showErrors,
  purchases,
  onPostClickWorkbook
}) => {
  if (loading) {
    return null;
  }

  if (!loading && !exported) {
    return (
      <Fragment>
        <button
          style={{ float: "right" }}
          className={'btn btn-sm m-2 btn-danger'}
          onClick={() => onPostClickWorkbook(purchases)}
          title={'WARNING: Purchases not yet exported!'}
        >
          {" "}
          Update in WorkBook
        </button>
      </Fragment>
    );
  }

  if (!loading && exported && showErrors) {
    return (
      <Fragment>
        <button
          style={{ float: "right" }}
          className={'btn btn-sm m-2 btn-outline-danger'}
          onClick={() => onPostClickWorkbook(purchases)}
          title={'WARNING: Purchases may have issues in Xero!'}
        >
          {" "}
          Update in WorkBook
        </button>
      </Fragment>
    );
  }

  if (!loading && exported && !showErrors) {
    return (
      <Fragment>
        <button
          style={{ float: "right" }}
          className={'btn btn-sm m-2 btn-success'}
          onClick={() => onPostClickWorkbook(purchases)}
          title={'Everything looks fine. Update in WorkBook?'}
        >
          {" "}
          Update in WorkBook
        </button>
      </Fragment>
    );
  }
};
export default WorkbookButton;
