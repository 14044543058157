import axios from "axios";
const onPostClickXero = async function onPostClickXero(invoices) {
  try {
    // Obtain Xero keys from profile to send with invoice data
      // Obtain Xero keys from profile to send with invoice data
      let tokenSet = await localStorage.getItem('tokenSet');
      let refresh = await localStorage.getItem('xrt');
      let currentTenant = await localStorage.getItem('currentOrg');
      let data = {...JSON.parse(tokenSet), ...JSON.parse(currentTenant), refresh}

    // IF NOT CURRENT ORG THEN THERE IS NO ACTIVE CONNECTION. SEND ERROR TO RECONNECT
    if (localStorage.getItem('currentOrg') === 0 ) {
      return "500"
    }

    let toPost = { invoices, data };
    // Send invoice data
    let res = await axios.post(
      "/api/post-invoices", toPost
    );
    return res.data;
  } catch (e) {
    console.log(e);
    return "500"
  }
};

export default onPostClickXero;
