import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { logoutUser } from "../../actions/redux/authActions";
import { clearCurrentProfile } from "../../actions/redux/profileActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import XeroConnectionStatus from './XeroConnectionStatus';
import { faKey, faInfoCircle, faHome, faUserPlus } from "@fortawesome/free-solid-svg-icons";

class Navbar extends Component {

  // IF LOGOUT IS CLICKED REMOVE CURRENT PROFILE FROM REDUX STATE AND TAKE BACK TO LOGIN PAGE
  onLogoutClick(e) {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.logoutUser();
  }

  render() {
    // GET IS AUTHENTICATED SO WE CAN DISPLAY LOGGED IN LINKS OR NOT
    const { isAuthenticated, user } = this.props.auth;

    // User Admin role gains access to add user link
    const adminLink = (
      <li style={{ marginLeft: "1px" }} className="nav-item">
        <a
          href="/register"
          className="nav-link"
        >
          <FontAwesomeIcon icon={faUserPlus} /> {"  "}
          Add User
        </a>
      </li>
    );

    // IF AUTHENTICATED THESE LINKS ARE DISPLAYED
    const authLinks = (
      <ul className="navbar-nav ml-auto">
        <XeroConnectionStatus />
        <li style={{ marginLeft: "1px" }} className="nav-item">
          <a
            href="/dashboard"
            className="nav-link"
          >
            <FontAwesomeIcon icon={faHome} /> {"  "}
            Home
          </a>
        </li>
        {
          user.role === "admin" && adminLink
        }
        <li style={{ marginLeft: "1px" }} className="nav-item">
          <a
            href="/updatepassword"
            className="nav-link"
          >
            <FontAwesomeIcon icon={faKey} /> {"  "}
            Update Password
          </a>
        </li>
        <li style={{ marginLeft: "1px" }} className="nav-item">
          <a
            href="/help"
            className="nav-link"
          >
            <FontAwesomeIcon icon={faInfoCircle} /> {"  "}
            Help
          </a>
        </li>
        <li style={{ marginLeft: "1px" }} className="nav-item">
          <a
            href="/"
            onClick={this.onLogoutClick.bind(this)}
            className="nav-link"
          >
            <img
              className="rounded-circle"
              src={user.avatar}
              alt={user.name}
              style={{ width: "25px", marginRight: "5px" }}
              title="You must have a Gravatar connected to your email to display an image"
            />{" "}
            Logout
          </a>
        </li>
      </ul>
    );

    // IF NOT AUTHENTICATED THESE LINKS ARE DISPLAYED
    const guestLinks = (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <NavLink className="nav-link" to="/login">
            Login
          </NavLink>
        </li>
      </ul>
    );

    return (
      <nav
        className="navbar navbar-expand-sm navbar-dark bg-dark mb-4 nav-container"
        style={{ position: "relative", width: "100%", minHeight: "57px" }}
      >
        <div
          style={{
            position: "relative",
            float: "left",
            marginLeft: isAuthenticated ? "80px" : "10px",
            fontSize: "20pt"
          }}
        >
          <ul className="navbar-nav ml-auto">
            {
              isAuthenticated ?
                <li className="navbar-brand">
                  Exports | Config
                </li> :
                <NavLink className="navbar-brand" to="/">
                  Xero Connector by Smartsoftware
                </NavLink>
            }
          </ul>
        </div>
        <div
          style={{
            position: "relative",
            float: "right"
          }}
          className="collapse navbar-collapse"
          id="mobile-nav"
        >
          {isAuthenticated ? authLinks : guestLinks}
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { logoutUser, clearCurrentProfile })(
  Navbar
);
