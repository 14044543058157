import {
  GET_CREDITOR_INVOICES,
  DELETE_CREDITOR_INVOICE,
  DELETE_CREDITOR_INVOICES,
  CREDITOR_INVOICES_LOADING,
  POST_CREDITOR_INVOICES,
  UPDATE_CREDITOR_INVOICES_STATUS,
  UPDATE_CREDITOR_INVOICE_SINGLE_STATUS
} from "../types";

const initialState = {
  creditorInvoices: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREDITOR_INVOICES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_CREDITOR_INVOICES:
      return {
        ...state,
        creditorInvoices: action.payload,
        loading: false
      };
    case DELETE_CREDITOR_INVOICE:
      return Object.assign({}, state, {
        creditorInvoices: [
          ...state.creditorInvoices.filter(
            creditorInvoice => creditorInvoice.Id !== action.payload
          )
        ]
      });
    case UPDATE_CREDITOR_INVOICES_STATUS:
      return {
        creditorInvoices: [
          ...state.creditorInvoices.map(item => {
            if (item.Status !== action.payload) {
              item.Status = action.payload;
            }
            return item;
          })
        ]
      };
    case UPDATE_CREDITOR_INVOICE_SINGLE_STATUS:
      return {
        creditorInvoices: [
          ...state.creditorInvoices.map(item => {
            if (item.Id === action.payload) {
              item.Status = action.value;
            }
            return item;
          })
        ]
      };
    case DELETE_CREDITOR_INVOICES:
      return { creditorInvoices: [] };
    case POST_CREDITOR_INVOICES:
      break;
    default:
      return state;
  }
}
