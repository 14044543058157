import React, { useState, useEffect } from "react";
import Select from "react-dropdown-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlug } from "@fortawesome/free-solid-svg-icons";


const XeroConnectionStatus = () => {
  // GET STATE FROM ORGS CONTEXT
  const [currentOrg, setCurrentOrg] = useState({});
  const [orgs, setOrgs] = useState([]);
  const [orgSelected, setOrgSelected] = useState(false);

  // useEffect IS A REACT HOOK THAT RUNS ON FIRST LOAD
  // https://reactjs.org/docs/hooks-effect.html
  // IF THE USER HAS CONNECTED TO XERO, XERO TENANTS WILL BE STORED WITHIN LOCAL STORAGE
  // CHECK AND POPULATE OPTIONS IN THE DROP DOWN 
  // https://www.npmjs.com/package/react-dropdown-select
  useEffect(() => {
    async function fetchOrgs() {
      let tenants = await localStorage.getItem("tenants");
      let current = await localStorage.getItem("currentOrg");

      if (tenants != "" && tenants !== null) {
        const tenantsArr = JSON.parse(tenants);
        setOrgs(tenantsArr);

        if (tenantsArr.length === 1) {
          localStorage.setItem("currentOrg", JSON.stringify(tenantsArr[0]));
          setOrgSelected(true);
        }
      }

      if (current != "" && current != null) {
        setCurrentOrg(JSON.parse(current));
        setOrgSelected(true);
      } else {
        setOrgSelected(false);
      }
    }
    fetchOrgs();
  }, [orgSelected]);

  async function setCurrent(e) {
    setCurrentOrg(e[0]);
    localStorage.setItem("currentOrg", JSON.stringify(e[0]));
    setOrgSelected(true);
  }

  const dropDown = (
    <Select
      placeholder={"Please select..."}
      labelField={"tenantName"}
      valueField={"tenantName"}
      options={orgs}
      values={Object.keys(currentOrg).length ? [currentOrg] : []}
      style={{ backgroundColor: "white", color: "#1673ba" }}
      onChange={(e) => setCurrent(e)}
    />
  );

  return (
    <>
      <li style={{ marginLeft: "1px" }} className="nav-item active">
        <span className="nav-link">
          <span className={orgSelected ? "conn" : "notConn"}>
            <FontAwesomeIcon icon={faPlug} /> 
            {orgSelected ? " Xero connected to " : " Xero not connected "}
            {orgs.length === 1 && currentOrg.tenantName}
          </span>
        </span>
      </li>
      {orgs.length > 1 && dropDown}
    </>
  );
};

export default XeroConnectionStatus;