import PropTypes from "prop-types";
import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { getCurrentProfile } from "../../actions/redux/profileActions";
import postWorkbookPayments from "../../actions/workbook/post/invoicePayments";
import {
  deleteXeroInvoices,
  getXeroInvoices,
  postInvoices
} from "../../actions/xero/getInvoices";
import Spinner from "../common/Spinner";
import {
  AllPosted,
  ConErr,
  Found,
  Looking,
  NoneFound,
  PostingWorkbook,
  Xero500,
  XerroNoErrors
} from "../conditionals/invoicePaymentsConditionals";
import ErrorItems from "./Errors";
import InvoicePaymentItem from "./InvoicePaymentItem";

class InvoicePayments extends Component {
  state = {
    exported: false,
    postingToWorkbook: false,
    postedToWorkbook: false,
    showErrors: false,
    showButtons: true,
    showHeading: true,
    errors: [],
    errCount: 0,
    loading: false,
    xero500: false,
    modalShow: false
  };

  componentDidMount() {
    this.props.getXeroInvoices();
  }

  handleShow = () => {
    this.setState({ modalShow: true });
  };

  // Event to post invoicePayments to WorkBook
  async onPostClickWorkbook(event, invoicePayments) {
    this.setState({ loading: true, postingToWorkbook: true });
    let res = await postWorkbookPayments(event, invoicePayments);
    console.log(res);

    let response = await res;
    this.setState({
      loading: false,
      showErrors: false,
      postingToWorkbook: false,
      postedToWorkbook: true
    });
    this.props.deleteXeroInvoices();
    return response;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.invoicePayments === 500 ||
      nextProps.invoicePayments === 500
    ) {
      this.setState({ showButtons: false, xero500: true });
    }
    if (
      this.props.invoicePayments === 401 ||
      nextProps.invoicePayments === 401
    ) {
      this.setState({ showButtons: false });
    }
  }

  render() {
    const { invoicePayments, loading } = this.props.invoicePayment;
    const {
      errors,
      showErrors,
      exported,
      showButtons,
      showHeading,
      xero500,
      errCount,
      postingToWorkbook,
      postedToWorkbook
    } = this.state;
    let InvoicePaymentItems;
    let errorItems;

    // HANDLE MODAL
    let modalClose = () => this.setState({ modalShow: false });

    // If invoicePayments are loading, push a spinner to the InvoicePaymentItems <div />
    if (invoicePayments === null || loading || this.state.loading) {
      InvoicePaymentItems = <Spinner animation="border" variant="primary" />;
    }
    else if (postedToWorkbook === true) {
      InvoicePaymentItems = <AllPosted />;
    } else if (
      postedToWorkbook === false &&
      invoicePayments === 500 &&
      !loading
    ) {
      InvoicePaymentItems = <NoneFound />;
    }
    // If WorkBook returns invoicePayments, push invoice response to the invoiceItems <div />
    else if (invoicePayments !== undefined && invoicePayments !== "WB connection error") {
      InvoicePaymentItems = (
        <TransitionGroup> {
          invoicePayments.map(invoicePayment => (
            <CSSTransition
              key={invoicePayment.Id}
              timeout={200}
              classNames="itemsAnimate"
            >
              <InvoicePaymentItem
                key={invoicePayment.Id}
                invoicePayment={invoicePayment}
              />
            </CSSTransition>
          ))
        }
        </TransitionGroup>
      )
      if (errors.length !== 0) {
        errorItems = errors.map(error => (
          <ErrorItems
            key={error.invoicePayment}
            show={this.state.modalShow}
            onHide={modalClose}
            errors={errors}
          />
        ));
      }
    }

    // WORKBOOK BUTTON CONDITIONALS
    let workbookButton;
    let buttonStyle;
    let buttonTitle;
    if (loading) {
      workbookButton = null;
    }
    if (!loading && errCount === 0) {
      buttonStyle = "btn btn-sm m-2 btn-success";
      buttonTitle = "";
    }
    if (!loading && invoicePayments !== "WB connection error") {
      workbookButton = (
        <button
          style={{ float: "left" }}
          className={buttonStyle}
          onClick={e => this.onPostClickWorkbook(e, invoicePayments)}
          title={buttonTitle}
        >
          {" "}
          Update in WorkBook
        </button>
      );
    }

    // HEADING TEXT CONDITIONALS
    let headingText;
    if (loading && !exported) {
      headingText = <Looking />;
    }
    if (postingToWorkbook) {
      headingText = <PostingWorkbook />;
    }
    if (
      !exported &&
      !loading &&
      !postedToWorkbook &&
      invoicePayments !== undefined &&
      invoicePayments !== 401 &&
      invoicePayments !== "WB connection error"
    ) {
      headingText = (
        <h4>
          <span style={{ color: "#4885ED" }}>{invoicePayments.length}</span>
          <Found />
        </h4>
      );
    }
    if (
      !postedToWorkbook &&
      !loading &&
      invoicePayments !== undefined &&
      invoicePayments.length === 0
    ) {
      headingText = <NoneFound />;
    }
    if (exported && errCount === 0) {
      headingText = <XerroNoErrors />;
    }
    if (!loading && xero500 === true) {
      headingText = <Xero500 />;
    }
    if (invoicePayments === "WB connection error") {
      headingText = <ConErr />;
    }

    return (
      <Container style={{ textAlign: "center" }}>
        {showHeading === true ? (
          <Row className="d-flex flex-column headingText">{headingText}</Row>
        ) : null}
        {InvoicePaymentItems !== undefined &&
          InvoicePaymentItems.length !== 0 &&
          showButtons &&
          !xero500 &&
          !loading &&
          invoicePayments.length !== 0 ? (
          <Row className="justify-content-sm-left paymentButton">
            {workbookButton}
          </Row>
        ) : null}
        <Row className="d-flex flex-column radioContainer">
          <Col xs={6}></Col>
        </Row>
        {showErrors ? (
          <Row className="d-flex flex-column errorsContainer">{errorItems}</Row>
        ) : null}
        {!xero500 ? (
          <Row className="d-flex flex-column">
            {InvoicePaymentItems}
          </Row>
        ) : null}
      </Container>
    );
  }
}

InvoicePayments.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteXeroInvoices: PropTypes.func,
  profile: PropTypes.object.isRequired,
  getXeroInvoices: PropTypes.func.isRequired,
  invoicePayment: PropTypes.object.isRequired,
  errors: PropTypes.object
};

const mapStateToProps = state => ({
  invoicePayment: state.invoicePayments,
  invoicePayments: state.invoicePayments.invoicePayments,
  profile: state.profile,
  errors: state.errors
});

const mapDispatchToProps = dispatch => {
  return {
    postInvoices: invoicePayments => dispatch(postInvoices(invoicePayments)),
    getXeroInvoices: invoicePayments =>
      dispatch(getXeroInvoices(invoicePayments)),
    deleteXeroInvoices: invoicePayments =>
      dispatch(deleteXeroInvoices(invoicePayments)),
    getCurrentProfile: profile => dispatch(getCurrentProfile(profile))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePayments);
