import axios from "axios";
import {
  DELETE_EXPENSE,
  DELETE_EXPENSES,
  EXPENSES_LOADING,
  GET_EXPENSES,
  POST_EXPENSES,
  UPDATE_EXPENSES_STATUS,
  UPDATE_EXPENSE_SINGLE_STATUS
} from "./types";

// Get ALL Expenses
export const getExpenses = () => dispatch => {
  dispatch(setExpensesLoading());

  const getExpenses = async () => {
    try {

      let response = await axios.post("/api/postFunctions", { action: GET_EXPENSES });

      if (response.data === "none") {
        dispatch({
          type: GET_EXPENSES,
          payload: []
        });
      } else {
        dispatch({
          type: GET_EXPENSES,
          payload: response.data
        });
      }
    } catch (error) {
      dispatch({
        type: GET_EXPENSES,
        payload: 9
      });
    }
  };
  getExpenses();
};

// Delete Expense
export const deleteExpense = Id => dispatch => {
  dispatch({
    type: DELETE_EXPENSE,
    payload: Id
  });
};

// Delete Expenses
export const deleteExpenses = expenses => dispatch => {
  dispatch({
    type: DELETE_EXPENSES,
    payload: expenses
  });
};

// Post Expenses
export const postExpenses = expenses => dispatch => {
  dispatch({
    type: POST_EXPENSES,
    payload: expenses
  });
};

// Expenses loading
export const setExpensesLoading = () => {
  return {
    type: EXPENSES_LOADING
  };
};

// Update Expenses status
export const updateStatus = value => dispatch => {
  dispatch({
    type: UPDATE_EXPENSES_STATUS,
    payload: value
  });
};

// Update single Expense status
export const updateSingleStatus = (Id, value) => dispatch => {
  dispatch({
    type: UPDATE_EXPENSE_SINGLE_STATUS,
    payload: Id,
    value
  });
};
