import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

const SubmitButtons = ({ buttonText, buttonStyle }) => (
  <Fragment>
    <Button
      style={buttonStyle}
      type="submit"
      variant="contained"
      color="primary"
    >
      {buttonText}
    </Button>
  </Fragment>
);

SubmitButtons.propTypes = {
  buttonText: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  buttonStyle: PropTypes.object.isRequired
};

export default SubmitButtons;
