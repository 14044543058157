import React from "react";
import { Button, Container, Modal, Table } from "react-bootstrap";

const ErrorItems = ({ show, onHide, errors }) => {
  return (
    <Modal
      show={show}
      {...errors}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Issues Found ...
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          "max-height": "calc(100vh - 210px)",
          "overflow-y": "auto",
        }}
      >
        <Container>
          <Table style={{ fontSize: "8pt" }} responsive="sm">
            <thead>
              <tr>
                <th>Expense</th>
                <th style={{ width: "25em" }}>Errors</th>
                <th style={{ paddingLeft: "50px" }}>Warnings</th>
              </tr>
            </thead>
            {errors.map((i) => {
              if (i.warnings !== "") {
                var warnings = i.warnings.map((i) => i);
              } else {
                warnings = "No warnings";
              }
              if (i.errors !== undefined) {
                var errors = i.errors.map((i) => i);
              } else {
                errors = "";
              }
              return (
                <tbody key={i.expense}>
                  <tr>
                    <td>{i.expense}</td>
                    <td>
                      {typeof errors != "string" ? (
                        <ul>
                          {errors.map((el, i) => (
                            <li key={i}>{el}</li>
                          ))}
                        </ul>
                      ) : (
                        <ul></ul>
                      )}
                    </td>
                    <td>
                      {typeof warnings != "string" ? (
                        <ul>
                          {warnings.map((el, i) => (
                            <li key={i}>{el}</li>
                          ))}
                        </ul>
                      ) : (
                        <ul></ul>
                      )}
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ErrorItems;
