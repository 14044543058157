// IMPORT ICONS FROM PACKAGE FONT AWESOME
// THESE WILL BE DISPLAYED IN THE SIDE BAR
import {
  faCashRegister,
  faCog,
  faCommentDollar,
  faFileInvoice,
  faFileInvoiceDollar,
  faMale,
  faPersonBooth,
  faPlug,
  faRemoveFormat
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { push as Menu } from "react-burger-menu";
import { connect } from "react-redux";

class Sidebar extends Component {
  render() {
    // ONLY RENDER SIDE BAR IF USER IS AUTHENTICATED
    const { isAuthenticated } = this.props.auth;
    return (
      <div>
        {isAuthenticated ? (
          <Menu>
            <h4 style={{ color: "#4885ed" }} className="menu-item">
              EXPORTS
            </h4>
            <a className="menu-item" href="/contacts" target="_self">
            <FontAwesomeIcon icon={faPersonBooth} />
            &nbsp;&nbsp;Contacts
          </a>
            <a className="menu-item" href="/invoices" target="_self">
              <FontAwesomeIcon icon={faFileInvoiceDollar} />
              &nbsp;&nbsp;Invoices
            </a>
            <a className="menu-item" href="/creditnotes" target="_self">
              <FontAwesomeIcon icon={faRemoveFormat} />
              &nbsp;&nbsp;Credit Notes
            </a>
            <a className="menu-item" href="/purchases" target="_self">
              <FontAwesomeIcon icon={faFileInvoice} />
              &nbsp;&nbsp;Purchases
            </a>
            <a className="menu-item" href="/creditorinvoices" target="_self">
              <FontAwesomeIcon icon={faCommentDollar} />
              &nbsp;&nbsp;Creditor Invoices
            </a>
            <a className="menu-item" href="/expenses" target="_self">
              <FontAwesomeIcon icon={faMale} />
              &nbsp;&nbsp;Expenses
            </a>
            <a className="menu-item" href="/invoicePayments" target="_self">
              <FontAwesomeIcon icon={faCashRegister} />
              &nbsp;&nbsp;Invoice Payments
            </a>
            <h4
              style={{ marginTop: "2em", color: "#4885ed" }}
              className="menu-item"
            >
              CONFIG
            </h4>
            <a className="menu-item" href="/edit-profile" target="_self">
              <FontAwesomeIcon icon={faCog} />
              &nbsp;&nbsp;Settings
            </a>
            <a className="menu-item" href="/xero" target="_self">
              <FontAwesomeIcon icon={faPlug} />
              &nbsp;&nbsp;Connect Xero
            </a>
          </Menu>
        ) : null}
      </div>
    );
  }
}

Sidebar.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(Sidebar);
