import {
  GET_EXPENSES,
  DELETE_EXPENSE,
  DELETE_EXPENSES,
  EXPENSES_LOADING,
  POST_EXPENSES,
  UPDATE_EXPENSES_STATUS,
  UPDATE_EXPENSE_SINGLE_STATUS
} from "../types";

const initialState = {
  expenses: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case EXPENSES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_EXPENSES:
      return {
        ...state,
        expenses: action.payload,
        loading: false
      };
    case DELETE_EXPENSE:
      return Object.assign({}, state, {
        expenses: [
          ...state.expenses.filter(expense => expense.Id !== action.payload)
        ]
      });
    case UPDATE_EXPENSES_STATUS:
      return {
        expenses: [
          ...state.expenses.map(item => {
            if (item.Status !== action.payload) {
              item.Status = action.payload;
            }
            return item;
          })
        ]
      };
    case UPDATE_EXPENSE_SINGLE_STATUS:
      return {
        expenses: [
          ...state.expenses.map(item => {
            if (item.Id === action.payload) {
              item.Status = action.value;
            }
            return item;
          })
        ]
      };
    case DELETE_EXPENSES:
      return { expenses: [] };
    case POST_EXPENSES:
      break;
    default:
      return state;
  }
}
