import format from "date-fns/format";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import { deleteXeroInvoice } from "../../actions/xero/getInvoices";

class InvoicePaymentItem extends Component {
  state = {
    isAuthorisedChecked: ""
  };

  onDeleteClick(e) {
    this.props.deleteXeroInvoice(this.props.invoicePayment.Id);
  }

  render() {
    const { invoicePayment } = this.props;
    // TO-DO FIX THIS WEIRD DATE ISSUE!
    const unformattedXeroDate = invoicePayment.FullyPaidOnDate;
    const returnDateNum = unformattedXeroDate.replace(/^\D+/g, "");
    const unixTimeStamp = returnDateNum.substring(
      0,
      returnDateNum.indexOf("+")
    );
    const formattedXeroDate = moment.unix(unixTimeStamp).toDate() / 1000;

    return (
      <Wrapper>
        <Card style={{ marginBottom: "2.5em" }} className="paymentCard">
          <Card.Header>
            <div
              style={{
                float: "left",
                fontWeight: "bold",
                fontSize: "1.2em"
              }}
            >
              Invoice# {invoicePayment.Number}
            </div>

            <div
              className="item-delete"
              style={{
                textAlign: "right",
                color: "red",
                cursor: "pointer",
                float: "right"
              }}
            >
              <i
                className="fa fa-times"
                aria-hidden="true"
                onClick={this.onDeleteClick.bind(this)}
              />
            </div>
          </Card.Header>

          <ListGroup variant="flush">
            <table className="table table-striped paymentTable">
              <thead>
                <tr style={{ textAlign: "left" }}>
                  <th style={{ width: "25%" }} scope="col">
                    Client
                  </th>
                  <th style={{ width: "25%" }} scope="col">
                    Reference
                  </th>
                  <th style={{ width: "12%" }} scope="col">
                    WB Total 
                  </th>
                  <th style={{ width: "12%" }} scope="col">
                    Xero Paid Total
                  </th>
                  <th style={{ width: "12%" }} scope="col">
                    WB Issued
                  </th>
                  <th style={{ width: "12%" }} scope="col">
                    Xero Paid Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: "left" }}>
                  <td>{invoicePayment.contact}</td>
                  <td>{invoicePayment.Headline}</td>
                  <td>${invoicePayment.AmountTotalCurrency}</td>
                  <td>${invoicePayment.xeroTotalPaid}</td>
                  <td>
                    {format(new Date(invoicePayment.PostDate), "DD/MM/YYYY")}
                  </td>
                  <td>{moment(formattedXeroDate).format("DD/MM/YYYY")}</td>
                </tr>
              </tbody>
            </table>
          </ListGroup>
        </Card>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  font-size: 12px;
  width: auto;
  margin-bottom: 1em;
`;

InvoicePaymentItem.propTypes = {
  invoicePayment: PropTypes.object.isRequired,
  deleteXeroInvoice: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    deleteXeroInvoice: invoicePayment =>
      dispatch(deleteXeroInvoice(invoicePayment))
  };
};

export default connect(mapDispatchToProps, { deleteXeroInvoice })(
  InvoicePaymentItem
);
