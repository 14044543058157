import React from 'react';

export default () => {
  return (
    <footer className="footer mt-5 text-center" style={{ padding: '0.5rem' }}>
      Copyright &copy; {new Date().getFullYear()}&nbsp;
      <a
        style={{
          color: 'inherit',
          textDecoration: 'inherit'
        }}
        href='https://www.smartsoftware.com.au'>
        Smartsoftware
      </a>
    </footer>
  );
};
