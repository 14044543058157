import axios from "axios";
import {
  CREDIT_NOTES_LOADING,
  DELETE_CREDIT_NOTE,
  DELETE_CREDIT_NOTES,
  GET_CREDIT_NOTES,
  POST_CREDIT_NOTES,
  UPDATE_CREDIT_NOTES_STATUS,
  UPDATE_CREDIT_NOTE_SINGLE_STATUS
} from "./types";

// GET CREDIT NOTES
export const getCreditNotes = () => dispatch => {
  dispatch(setCreditNotesLoading());

  const getCreditNotes = async () => {
    try {

      let response = await axios.post("/api/postFunctions", { action: GET_CREDIT_NOTES });

      if (response.data === "none") {
        dispatch({
          type: GET_CREDIT_NOTES,
          payload: []
        });
      } else {
        dispatch({
          type: GET_CREDIT_NOTES,
          payload: response.data
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CREDIT_NOTES,
        payload: 9
      });
    }
  };
  getCreditNotes();
};

// Delete Credit Note
export const deleteCreditNote = Id => dispatch => {
  dispatch({
    type: DELETE_CREDIT_NOTE,
    payload: Id
  });
};

// Delete Credit Notes
export const deleteCreditNotes = creditNotes => dispatch => {
  dispatch({
    type: DELETE_CREDIT_NOTES,
    payload: creditNotes
  });
};

// Post Credit Notes
export const postCreditNotes = creditNotes => dispatch => {
  dispatch({
    type: POST_CREDIT_NOTES,
    payload: creditNotes
  });
};

// Credit Notes loading
export const setCreditNotesLoading = () => {
  return {
    type: CREDIT_NOTES_LOADING
  };
};

// Update Credit Notes Status
export const updateStatus = value => dispatch => {
  dispatch({
    type: UPDATE_CREDIT_NOTES_STATUS,
    payload: value
  });
};

// Update Single Credit Note Status
export const updateSingleStatus = (Id, value) => dispatch => {
  dispatch({
    type: UPDATE_CREDIT_NOTE_SINGLE_STATUS,
    payload: Id,
    value
  });
};
