import axios from "axios";

const postWorkbook = async function onPostClickWorkbook(expenses) {
  try {
    // IDs ARE NEEDED TO POST TO THE WORKBOOKS API
    let expensesToPostToWorkbook = expenses.map(ids => ids.Id);
    // GET PROFILE DATA TO RETRIEVE WORKBOOK CREDENTIALS
    const profileData = await axios.get("/api/profile");
    const workbookURL = await profileData.data.workbookURL;
    const workbookUserName = await profileData.data.workbookUserName;
    const workbookPassword = await profileData.data.workbookPassword;
    const credentials = workbookUserName + ":" + workbookPassword;
    // ENCODE USERNAME AND PASSWORD TO BASE64 TO ENCLUDE IN HTTP REQUEST HEADERS
    const encoded = Buffer.from(credentials).toString("base64");

    const toPost = { workbookURL, encoded, expensesToPostToWorkbook };
    // Send expenses data
    const response = await axios.post("/api/post-wb-expenses", toPost);
    return response.data;

  } catch (e) {
    console.log(e);
    return false;
  }
};

export default postWorkbook;
