import axios from "axios";
import { FieldValidationErrorsElement } from "xero-node/dist/gen/model/assets/fieldValidationErrorsElement";
import {
  DELETE_INVOICE,
  DELETE_INVOICES,
  GET_INVOICES,
  INVOICES_LOADING,
  POST_INVOICES,
  UPDATE_INVOICES_STATUS,
  UPDATE_INVOICE_SINGLE_STATUS,
} from "./types";

// Get ALL Invoices
export const getInvoices = () => (dispatch) => {
  dispatch(setInvoicesLoading());

  const getInvoices = async () => {
    try {

      let response = await axios.post("/api/postFunctions", { action: GET_INVOICES });

      if (response.data === "none") {
        dispatch({
          type: GET_INVOICES,
          payload: [],
        });
      } else {
        dispatch({
          type: GET_INVOICES,
          payload: response.data,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_INVOICES,
        payload: 9,
      });
    }
  }
  getInvoices();
};

// Delete Invoice
export const deleteInvoice = (Id) => (dispatch) => {
  dispatch({
    type: DELETE_INVOICE,
    payload: Id,
  });
};

// Delete Invoices
export const deleteInvoices = (invoices) => (dispatch) => {
  dispatch({
    type: DELETE_INVOICES,
    payload: invoices,
  });
};

// Post Invoices
export const postInvoices = (invoices) => (dispatch) => {
  dispatch({
    type: POST_INVOICES,
    payload: invoices,
  });
};

// Invoices loading
export const setInvoicesLoading = () => {
  return {
    type: INVOICES_LOADING,
  };
};

// Update invoice status
export const updateStatus = (value) => (dispatch) => {
  dispatch({
    type: UPDATE_INVOICES_STATUS,
    payload: value,
  });
};

// Update single invoice status
export const updateSingleStatus = (Id, value) => (dispatch) => {
  dispatch({
    type: UPDATE_INVOICE_SINGLE_STATUS,
    payload: Id,
    value,
  });
};
