import axios from "axios";
import {
  DELETE_PURCHASE,
  DELETE_PURCHASES,
  GET_PURCHASES,
  POST_PURCHASES,
  PURCHASES_LOADING,
  UPDATE_PURCHASES_STATUS,
  UPDATE_PURCHASE_SINGLE_STATUS
} from "./types";

export const getPurchases = () => dispatch => {
  dispatch(setPurchasesLoading());

  const getPurchases = async () => {
    try {

      let response = await axios.post("/api/postFunctions", { action: GET_PURCHASES });

      if (response.data === "none") {
        dispatch({
          type: GET_PURCHASES,
          payload: []
        });
      } else {
        dispatch({
          type: GET_PURCHASES,
          payload: response.data
        });
      }
    } catch (error) {
      dispatch({
        type: GET_PURCHASES,
        payload: 9
      });
    }
  };
  getPurchases();
};


// Delete Purchase
export const deletePurchase = Id => dispatch => {
  dispatch({
    type: DELETE_PURCHASE,
    payload: Id
  });
};

// Delete Purchases
export const deletePurchases = purchases => dispatch => {
  dispatch({
    type: DELETE_PURCHASES,
    payload: purchases
  });
};

// Post Purchases
export const postPurchases = purchases => dispatch => {
  dispatch({
    type: POST_PURCHASES,
    payload: purchases
  });
};

// Purchases loading
export const setPurchasesLoading = () => {
  return {
    type: PURCHASES_LOADING
  };
};

// Update purchases status
export const updateStatus = value => dispatch => {
  dispatch({
    type: UPDATE_PURCHASES_STATUS,
    payload: value
  });
};

// Update single purchase status
export const updateSingleStatus = (Id, value) => dispatch => {
  dispatch({
    type: UPDATE_PURCHASE_SINGLE_STATUS,
    payload: Id,
    value
  });
};
