import format from "date-fns/format";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  deletePurchase,
  updateSingleStatus,
} from "../../actions/redux/purchaseActions";

class PurchaseItem extends Component {
  state = {
    showPurchaseInfo: false,
    isDraftChecked: "checked",
    isAuthorisedChecked: "",
  };

  onDeleteClick(e) {
    this.props.deletePurchase(this.props.purchase.Id);
  }

  handleDraftChange = () => {
    this.props.updateSingleStatus(this.props.purchase.Id, "DRAFT");
    this.setState({
      isDraftChecked: "checked",
      isAuthorisedChecked: "",
    });
  };

  handleAcceptedChange = () => {
    this.props.updateSingleStatus(this.props.purchase.Id, "AUTHORISED");
    this.setState({
      isDraftChecked: "",
      isAuthorisedChecked: "checked",
    });
  };

  componentWillReceiveProps() {
    if (this.props.purchase.Status === "DRAFT") {
      return this.setState({
        isDraftChecked: "checked",
        isAuthorisedChecked: "",
      });
    }
    if (this.props.purchase.Status === "AUTHORISED") {
      return this.setState({
        isDraftChecked: "",
        isAuthorisedChecked: "checked",
      });
    }
  }

  render() {
    const { purchase } = this.props;
    const {
      showPurchaseInfo,
      isDraftChecked,
      isAuthorisedChecked,
    } = this.state;
    return (
      <Wrapper>
        <div className="card card-header bg-light">
          <div>
            <div className="radio-status item-radio">
              <input
                type="radio"
                value="DRAFT"
                checked={isDraftChecked}
                name={purchase.Id}
                onChange={this.handleDraftChange.bind(this)}
              />
              Draft
              <input
                type="radio"
                value="AUTHORISED"
                checked={isAuthorisedChecked}
                name={purchase.Id}
                onChange={this.handleAcceptedChange.bind(this)}
              />
              Authorised
            </div>
            <div
              className="item-delete"
              style={{
                textAlign: "right",
                color: "red",
                cursor: "pointer",
              }}
            >
              <i
                className="fa fa-times"
                aria-hidden="true"
                onClick={this.onDeleteClick.bind(this)}
              />
            </div>
          </div>

          <ul className="list-group-items list-group  list-group-flush">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }} scope="col">
                      PO#
                    </th>
                    <th style={{ width: "15%" }} scope="col">
                      Supplier
                    </th>
                    <th style={{ width: "15%" }} scope="col">
                      Issued
                    </th>
                    <th style={{ width: "15%" }} scope="col">
                      Delivery Date
                    </th>
                    <th style={{ width: "15%" }} scope="col">
                      Total
                    </th>
                    <th style={{ width: "45%" }} scope="col">
                      Reference
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{purchase.PurchaseOrderNumber}</td>
                    <td>{purchase.Contact.Name}</td>
                    <td>{format(new Date(purchase.Date), "DD/MM/YYYY")}</td>
                    <td>
                      {format(new Date(purchase.DeliveryDate), "DD/MM/YYYY")}
                    </td>
                    <td>${purchase.Total}</td>
                    <td>{purchase.Reference}</td>
                  </tr>
                </tbody>
              </table>
              <Button
                className="lineItemsButton"
                onClick={() =>
                  this.setState({ showPurchaseInfo: !showPurchaseInfo })
                }
                variant="primary"
                size="sm"
                aria-controls="line-items"
                aria-expanded={showPurchaseInfo}
                style={{ marginBottom: "1em", marginTop: "1em" }}
              >
                Line Items
              </Button>
              <Collapse in={this.state.showPurchaseInfo}>
                <div id="line-items">
                  <ul className="list-group">
                    <table className="table table-striped">
                      <thead className="thead-dark">
                        <tr>
                          <th style={{ width: "30%" }} scope="col">
                            Description
                          </th>
                          <th scope="col">Qty</th>
                          <th scope="col">Unit $</th>
                          <th scope="col">Acc code</th>
                        </tr>
                      </thead>
                      <tbody>
                        {purchase.LineItems.map((item) => {
                          return (
                            <tr key={item.Id}>
                              <td>{item.Subject}</td>
                              <td>{item.Unit}</td>
                              <td>${item.UnitCost}</td>
                              <td>{item.AccountNumber}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </ul>
                </div>
              </Collapse>
            </div>
          </ul>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: auto;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px 2px;
  textshadow: 0px 5px 15px rgba(255, 255, 255, 0.5);
  margin-bottom: 1em;
`;

PurchaseItem.propTypes = {
  purchase: PropTypes.object.isRequired,
  deletePurchase: PropTypes.func,
  updateSingleStatus: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    deletePurchase: (purchase) => dispatch(deletePurchase(purchase)),
    updateSingleStatus: (purchase) => dispatch(updateSingleStatus(purchase)),
  };
};

export default connect(mapDispatchToProps, {
  deletePurchase,
  updateSingleStatus,
})(PurchaseItem);
