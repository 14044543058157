import React, { Fragment } from 'react';

function Found() {
  return <Fragment>&nbsp;&nbsp;Invoice Payments ready to update</Fragment>;
}

function Looking() {
  return (
    <Fragment>
      <h4>Looking for invoice payments ...</h4>
    </Fragment>
  );
}

function ConErr() {
  return (
    <Fragment>
      <h4 style={{ color: "#d9534f" }}>Error</h4>
      <p>
        WorkBook connection error. Please review your connection settings and
        reload.
      </p>
    </Fragment>
  );
}

function FinalizedWorkbook() {
  return (
    <Fragment>
      <h4>
        Invoice payments finalized in WorkBook. Refresh this page to reload.
      </h4>
    </Fragment>
  );
}

function NoneFound() {
  return (
    <Fragment>
      <h4 style={{ color: '#4885ED' }}>No payments found</h4>
      <p>Please review your payments in Xero and reload</p>
    </Fragment>
  );
}

function NoErrXero() {
  return (
    <h4>No Errors!</h4>
  );
}

function ExportingXero() {
  return <h4 style={{ color: '#4885ED' }}>Exporting to Xero</h4>;
}

function PostingWorkbook() {
  return <h4 style={{ color: '#4885ED' }}>Posting to WorkBook</h4>;
}

function AllPosted() {
  return (
    <Fragment>
      <h4 style={{ color: '#4885ED' }}>Everything is posted!</h4>
      <p>Please reload the page to export more invoices</p>
    </Fragment>
  );
}

function XerroNoErrors() {
  return (
    <Fragment>
      <h4 style={{ color: '#4885ED' }}>Everything has Exported!</h4>
      <p>
        There were no errors or warnings, please feel free to update invoices in
        WorkBook
      </p>
    </Fragment>
  );
}

function IssuesFound() {
  return (
    <Fragment>
      <h4 style={{ color: '#d9534f' }}>Issues found ...</h4>
      <p>
        Please review the items below. You may wish to remove items in Xero and
        then reload this page to begin again...
      </p>
    </Fragment>
  );
}

function Xero500() {
  return (
    <Fragment>
      <h4 style={{ color: '#d9534f' }}>Xero connection issue ...</h4>
      <p>Xero returned a connection error, please review your settings...</p>
    </Fragment>
  );
}

export {
  Found,
  Looking,
  ConErr,
  FinalizedWorkbook,
  NoErrXero,
  NoneFound,
  ExportingXero,
  PostingWorkbook,
  AllPosted,
  XerroNoErrors,
  IssuesFound,
  Xero500
};
