import {
  CREDIT_NOTES_LOADING,
  DELETE_CREDIT_NOTE,
  DELETE_CREDIT_NOTES,
  GET_CREDIT_NOTES,
  POST_CREDIT_NOTES,
  UPDATE_CREDIT_NOTES_STATUS,
  UPDATE_CREDIT_NOTE_SINGLE_STATUS
} from "../types";

const initialState = {
  creditNotes: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREDIT_NOTES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_CREDIT_NOTES:
      return {
        ...state,
        creditNotes: action.payload,
        loading: false
      };
    case DELETE_CREDIT_NOTE:
      return Object.assign({}, state, {
        creditNotes: [
          ...state.creditNotes.filter(
            creditNote => creditNote.Id !== action.payload
          )
        ]
      });
    case UPDATE_CREDIT_NOTES_STATUS:
      return {
        creditNotes: [
          ...state.creditNotes.map(item => {
            if (item.Status !== action.payload) {
              item.Status = action.payload;
            }
            return item;
          })
        ]
      };
    case UPDATE_CREDIT_NOTE_SINGLE_STATUS:
      return {
        creditNotes: [
          ...state.creditNotes.map(item => {
            if (item.Id === action.payload) {
              item.Status = action.value;
            }
            return item;
          })
        ]
      };
    case DELETE_CREDIT_NOTES:
      return { creditNotes: [] };
    case POST_CREDIT_NOTES:
      break;
    default:
      return state;
  }
}
