import React, { createContext, useReducer } from "react";
import ContactsReducer from "./ContactsReducer";
import axios from "axios";

// INIT STATE
const initalState = {
  contacts: [],
  loading: true,
};

// CREATE CONTEXT
export const ContactsContext = createContext(initalState);

// PROVIDER COMPONANT
export const ContactsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ContactsReducer, initalState);

  /////////////////////
  // GET CONTACTS FROM XERO
  ////////////////////
  async function fetchContacts(event) {
    try {
      // Obtain Xero keys from profile to send with invoice data
      let tokenSet = await localStorage.getItem('tokenSet');
      let refresh = await localStorage.getItem('xrt');
      let currentTenant = await localStorage.getItem('currentOrg');
      let data = {...JSON.parse(tokenSet), ...JSON.parse(currentTenant), refresh}
      let toPost = { data };

      // Send invoice data
      let contacts = await axios.post("/api/get-contacts", toPost);
      dispatch({
        type: "GET_CONTACTS",
        payload: contacts.data,
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <ContactsContext.Provider
      value={{
        contacts: state.contacts,
        loading: state.loading,
        fetchContacts,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};
