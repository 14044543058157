import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SideBar from "../src/components/layout/sidebar";
import { logoutUser, setCurrentUser } from "./actions/redux/authActions";
import { clearCurrentProfile } from "./actions/redux/profileActions";
import "./App.css";
import Help from "./components/help/help"
import ForgotPassword from "./components/auth/ForgotPassword";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ResetPassword from "./components/auth/ResetPassword";
import UpdatePassword from "./components/auth/UpdatePassword";
import PrivateRoute from "./components/common/PrivateRoute";
import AdminRoute from "./components/common/AdminRoute";
import CreditNotes from "./components/creditNotes/CreditNotes";
import CreditorInvoices from "./components/creditorInvoices/CreditorInvoices";
import Dashboard from "./components/dashboard/Dashboard";
import EditProfile from "./components/profile/EditProfile";
import Expenses from "./components/expenses/Expenses";
import Invoices from "./components/invoices/Invoices";
import Contacts from "./components/contacts/Contacts";
import Footer from "./components/layout/Footer";
import Landing from "./components/layout/Landing";
import Navbar from "./components/layout/Navbar";
import NotFound from "./components/not-found/NotFound";
import InvoicePayments from "./components/payments/InvoicePayments";
import purchases from "./components/purchases/Purchases";
import Xero from "./components/xeroOauth/xero";
import XeroCallback from "./components/xeroOauth/xeroCallback";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import { ContactsProvider } from "./components/context/ContactsState";

class App extends Component {
  render() {
    if (localStorage.jwtToken) {
      // Set auth token header auth
      setAuthToken(localStorage.jwtToken);
      // Decode token and get user info and exp
      const decoded = jwt_decode(localStorage.jwtToken);
      // Set user and isAuthenticated
      store.dispatch(setCurrentUser(decoded));

      // Check for expired token
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(logoutUser());
        // Clear current Profile
        store.dispatch(clearCurrentProfile());
        // Redirect to login
        window.location.href = "/login";
      }
    }
    // ENSURE OUR LOCAL STORAGE EXISTS
    const currentTime = Date.now() / 1000;
    // IF EXPIRED CLEAR ALL DATA FROM THE XERO DROP DOWN
    if (
      localStorage.getItem("xero_expiry") < currentTime ||
      localStorage.getItem("xero_expiry") === null
    ) {
      localStorage.setItem("xero_expiry", "");
      localStorage.setItem("tenants", "");
      localStorage.setItem("currentOrg", "");
    }
    if (localStorage.getItem("tenants") === null) {
      localStorage.setItem("tenants", "");
    }
    if (localStorage.getItem("currentOrg") === null) {
      localStorage.setItem("currentOrg", "");
    }

    return (
      <Provider store={store}>
        <Router>
          <div id="App" className="App">
            <SideBar outerContainerId={"App"} />
            <Navbar />
            <Route exact path="/" component={(props) => <Landing {...props} />} />
            <div className="container">
              <Route exact path="/login" component={(props) => <Login {...props} />} />
              <Route exact path="/forgotPassword" component={ForgotPassword} />
              <Route exact path="/reset" component={ResetPassword} />
              <Switch>
                <AdminRoute exact path="/register" component={Register} />
              </Switch>
              <Switch>
                <PrivateRoute exact path="/updatepassword" component={UpdatePassword} />
              </Switch>
              <Switch>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
              </Switch>
              <Switch>
                <PrivateRoute exact path="/invoices" component={Invoices} />
              </Switch>
              <Switch>
                <PrivateRoute
                  exact
                  path="/creditnotes"
                  component={CreditNotes}
                />
              </Switch>
              <Switch>
                <PrivateRoute exact path="/xero" component={Xero} />
              </Switch>
              <Switch>
                <PrivateRoute
                  exact
                  path="/xeroCallback"
                  component={XeroCallback}
                />
              </Switch>
              <Switch>
                <PrivateRoute exact path="/purchases" component={purchases} />
              </Switch>
              <Switch>
                <PrivateRoute
                  exact
                  path="/creditorinvoices"
                  component={CreditorInvoices}
                />
              </Switch>
              <ContactsProvider>
                <Switch>
                  <PrivateRoute exact path="/contacts" component={Contacts} />
                </Switch>
              </ContactsProvider>
              <Switch>
                <PrivateRoute exact path="/expenses" component={Expenses} />
              </Switch>
              <Switch>
                <PrivateRoute
                  exact
                  path="/invoicepayments"
                  component={InvoicePayments}
                />
              </Switch>
              <Switch>
                <PrivateRoute
                  exact
                  path="/edit-profile"
                  component={EditProfile}
                />
              </Switch>
              <Switch>
                <PrivateRoute exact path="/help" component={Help} />
              </Switch>
              <Route exact path="/not-found" component={NotFound} />
            </div>
            <Footer />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
