import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Container, Row } from "react-bootstrap";

const Xero = () => {
  let [now, setNow] = useState(0);

  // useEffect IS A REACT HOOK THAT RUNS ON FIRST LOAD
  // https://reactjs.org/docs/hooks-effect.html
  useEffect(() => {
    connect();
    
    // SILLY FUNCTION TO MOVE THE PROGRESS BAR
    const interval = setInterval(() => {
      setNow((now) => now + 15);
      if (now >= 100) {
        now = 0 
      return () => clearInterval(interval)
    };
    }, 200);

  }, []);

  async function connect() {
    // THE BELOW USES THE api/connect (in server.js) ROUTE ON THE SERVER. THIS USES THE XERO SDK TO BUILD A URL REDIRECT TO XERO
    let response = await axios.get("/connect");
    window.location.replace(response.data);
  }

  return (
    <Container>
      <Row
        style={{ marginBottom: "5em" }}
        className="d-flex flex-column headingText"
      >
        <h1>Contacting Xero ...</h1>
      </Row>
      
        <ProgressBar now={now} max={100}/>
  
    </Container>
  );
};

export default Xero;
