import {
  GET_PURCHASES,
  DELETE_PURCHASE,
  DELETE_PURCHASES,
  PURCHASES_LOADING,
  POST_PURCHASES,
  UPDATE_PURCHASES_STATUS,
  UPDATE_PURCHASE_SINGLE_STATUS
} from "../types";

const initialState = {
  purchases: [],
  loading: false
};

export default function(state = initialState, action, purchases) {
  switch (action.type) {
    case PURCHASES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_PURCHASES:
      return {
        ...state,
        purchases: action.payload,
        loading: false
      };
    case DELETE_PURCHASE:
      return Object.assign({}, state, {
        purchases: [
          ...state.purchases.filter(purchase => purchase.Id !== action.payload)
        ]
      });
    case UPDATE_PURCHASES_STATUS:
      return {
        purchases: [
          ...state.purchases.map(item => {
            if (item.Status !== action.payload) {
              item.Status = action.payload;
            }
            return item;
          })
        ]
      };
    case UPDATE_PURCHASE_SINGLE_STATUS:
      return {
        purchases: [
          ...state.purchases.map(item => {
            if (item.Id === action.payload) {
              item.Status = action.value;
            }
            return item;
          })
        ]
      };
    case DELETE_PURCHASES:
      return { purchases: [] };
    case POST_PURCHASES:
      return {
        ...state,
        purchases: action.payload,
        loading: true
      };
    default:
      return state;
  }
}
