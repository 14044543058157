import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { registerUser } from "../../actions/redux/authActions";
import TextFieldGroup from "../common/TextFieldGroup";
import { LinkButtons } from "./authComponents";
import Button from "@material-ui/core/Button";

const buttonStyle = {
  marginTop: "1.5em",
  backgroundColor: "#4885ED"
}
class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      errors: {},
      userAdded: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.user.role !== "admin") {
      this.props.history.push("/login");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async onSubmit(e) {
    e.preventDefault();

    const newUser = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2
    };

    let added = await this.props.registerUser(newUser, this.props.history);
    if (added === true) this.setState({ userAdded: added });
  }

  reset() {
    this.setState({
      name: "",
      email: "",
      password: "",
      password2: "",
      errors: {},
      userAdded: false
    });
  }

  render() {
    const { errors, userAdded, name } = this.state;

    const createUserForm = (
      <div className="col-md-8 m-auto">
        <h1 className="display-4 text-center">Add User</h1>
        <p className="lead text-center">
          Create a Xero Connector account
        </p>
        <form noValidate onSubmit={this.onSubmit}>
          <TextFieldGroup
            placeholder="Name"
            name="name"
            value={this.state.name}
            onChange={this.onChange}
            error={errors.name}
          />
          <TextFieldGroup
            placeholder="Email"
            name="email"
            type="email"
            value={this.state.email}
            onChange={this.onChange}
            error={
              errors.email &&
              <>
                {errors.email}
                <a href='/forgotPassword'>https://xeroconnector.smartsoftware.com.au/forgotPassword</a>, and advise them to reset their password
              </>
            }
          />
          <TextFieldGroup
            placeholder="Password"
            name="password"
            type="password"
            value={this.state.password}
            onChange={this.onChange}
            error={errors.password}
          />
          <TextFieldGroup
            placeholder="Confirm Password"
            name="password2"
            type="password"
            value={this.state.password2}
            onChange={this.onChange}
            error={errors.password2}
          />
          <input
            type="submit"
            value="submit"
            className="btn btn-primary btn-sm"
            style={{ marginLeft: "43%" }}
          />
        </form>
      </div>
    );

    const confirmAddedUser = (
      <div className="col-md-8 m-auto">
        <div className="centreDisplay">
          <h4 className="confirmStyle">The user "{name}" has been successfully added.</h4>
          <LinkButtons
            buttonStyle={{ marginTop: "2em", marginRight: "1.5em" }}
            buttonText="Go back Home"
            link={`/dashboard`}
          />
          <Button
            color="primary"
            style={{ marginTop: "2em", marginLeft: "1.5em" }}
            variant="contained"
            onClick={this.reset}
          >
            Add another User
          </Button>
        </div>
      </div>
    );

    return (
      <div className="register">
        <div className="container">
          <div className="row">
            {userAdded ? confirmAddedUser : createUserForm}
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerUser }
)(withRouter(Register));
