import React, { Fragment } from "react";
import { Button } from "react-bootstrap";

const XeroButton = ({
  loading,
  exported,
  showErrors,
  errors,
  purchases,
  onPostClickXero,
  handleModalShow
}) => {
  if (!loading && !exported) {
    return (
      <Fragment>
        <button
          onClick={() => onPostClickXero(purchases)}
          className={`btn btn-sm m-2 btn-primary`}
          data-toggle="tooltip"
          data-html="true"
          title={"Purchases Loaded, export to Xero?"}
        >
          {" "}
          Export to Xero
        </button>
      </Fragment>
    );
  }

  if (!loading && exported && showErrors) {
    return (
      <Fragment>
        <Button
          variant="danger"
          onClick={() => handleModalShow(errors)}
        >
          Show Errors
        </Button>
      </Fragment>
    );
  }
  if (loading || (exported && !showErrors)) {
    return null;
  }
};
export default XeroButton;
