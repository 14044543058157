import React, { Fragment } from "react";

//
// DEPENING ON THE STATE OF THE PARENT COMPONANT
// STATE IS PASSED IN HERE AND THE CORRECT
// HEADING TEXT WILL BE SENT BACK AND USED
// https://reactjs.org/docs/components-and-props.html
//
// FRAGMENT IS A JSX ATTRIBUTE THAT WORKS LIKE A DIV
// IT IS USED IN REACT RATHER THAN A DIV AS A DIV MAY
// IN SOME CASES CAUSE ALIGMENT ISSUES
// https://reactjs.org/docs/fragments.html
//

const Headers = ({
  loading,
  exported,
  exportingToXero,
  postingToWorkbook,
  creditNotes,
  showErrors,
  xero500,
  postedToWorkbook,
  postError
}) => {
  if (creditNotes === 9) {
    return (
      <Fragment>
        <h4 style={{ color: "#d9534f" }}>Error</h4>
        <p>
          WorkBook connection error. Please review your connection settings and
          reload.
        </p>
      </Fragment>
    );
  }
  if (loading && !exported) {
    return (
      <Fragment>
        <h4>Looking for Credit Notes ...</h4>
      </Fragment>
    );
  }
  if (exportingToXero) {
    return <h4 style={{ color: "#4885ED" }}>Exporting to Xero</h4>;
  }
  if (postingToWorkbook) {
    return <h4 style={{ color: "#4885ED" }}>Posting to WorkBook</h4>;
  }
  if (
    !exported &&
    !xero500 &&
    !loading &&
    creditNotes !== undefined &&
    creditNotes.length > 0
  ) {
    return (
      <Fragment>
        <h4>
          <span style={{ color: "#4885ED" }}>{creditNotes.length}</span>
          <span>&nbsp;&nbsp;Credit Notes ready for export</span>
        </h4>
      </Fragment>
    );
  }
  if (
    !exported &&
    !loading &&
    creditNotes !== undefined &&
    creditNotes.length === 0
  ) {
    return (
      <Fragment>
        <h4 style={{ color: "#4885ED" }}>No Credit Notes found</h4>
        <p>Please review your Credit Note list in WorkBook and reload</p>
      </Fragment>
    );
  }
  if (exported && !showErrors && !postedToWorkbook) {
    return (
      <Fragment>
        <h4 style={{ color: "#4885ED" }}>Everything has Exported!</h4>
        <p>
          There were no errors or warnings, please feel free to update Credit Notes
          in WorkBook
        </p>
      </Fragment>
    );
  }

  if (exported && showErrors) {
    return (
      <Fragment>
        <h4 style={{ color: "#d9534f" }}>Issues found ...</h4>
        <p>
          Please review the items below. You may wish to remove items in Xero
          and then reload this page to begin again...
        </p>
      </Fragment>
    );
  }

  if (!loading && xero500) {
    return (
      <Fragment>
        <h4 style={{ color: "#d9534f" }}>Xero connection issue...</h4>
        <p>Xero returned a connection error, please review your settings...</p>
      </Fragment>
    );
  }

  if (postError) {
    return (
      <Fragment>
        <h4 style={{ color: "#d9534f" }}>WorkBook connection issue...</h4>
        <p>WorkBook connection error. Please review your connection settings and
          reload.</p>
      </Fragment>
    );
  }

  if (postedToWorkbook) {
    return (
      <Fragment>
        <h4 style={{ color: "#4885ED" }}>Everything is posted!</h4>
        <p>Please reload the page to export more Credit Notes</p>
      </Fragment>
    );
  }

};
export default Headers;
