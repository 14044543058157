import React, { useState, useContext, useEffect } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { ContactsContext } from "../context/ContactsState";
import {
  Found,
  Looking,
  ShowFetchData,
  NotConnected,
} from "../conditionals/contactsHeaderConditionals";
import { CSVLink } from "react-csv";
import Spinner from "../common/Spinner";

const Contacts = () => {
  const { contacts, fetchContacts } = useContext(ContactsContext);

  // SET DEFAULT STATE
  const [showHeading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [xeroConnected, setXeroConnected] = useState(false);
  const [fetchedContacts, setfetchedContacts] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("currentOrg").length !== 0) {
      setXeroConnected(true);
    }
  }, [xeroConnected, setXeroConnected]);

  // FUNCTION TO FETCH INVOICES AND SETS THEM TO STATE
  const getContacts = async (event) => {
    setLoading(true);
    const getContacts = fetchContacts(event);
    Promise.all([getContacts]).then(() => {
      setfetchedContacts(true);
      setLoading(false);
    });
  };

  // HEADERS
  let headingText;
  if (
    fetchedContacts &&
    !loading &&
    contacts !== undefined &&
    contacts.length > 0 &&
    xeroConnected
  ) {
    headingText = (
      <h4>
        <span style={{ color: "#1674ba" }}>{contacts.length}</span>
        <Found />
      </h4>
    );
  }

  if (!xeroConnected) {
    headingText = <NotConnected />;
  }

  if (loading && !fetchedContacts && xeroConnected) {
    headingText = <Looking />;
  }

  if (!fetchedContacts && !loading && xeroConnected) {
    headingText = <ShowFetchData />;
  }

  return (
    <Container>
      {showHeading ? (
        <Row
          style={{ marginBottom: "5em" }}
          className="d-flex flex-column headingText"
        >
          {headingText}
        </Row>
      ) : null}
      {!fetchedContacts && !loading && xeroConnected ? (
        <Row className="d-flex flex-column headingText">
          <Button
            className="buttonClass"
            onClick={(event) => getContacts(event)}
            variant="primary"
            style={{
              display: "block",
              margin: "0 auto",
              width: "10em",
              marginTop: "2em",
            }}
          >
            Fetch Contacts
          </Button>
        </Row>
      ) : null}
      {loading ? <Spinner animation="border" variant="primary" /> : null}
      {fetchedContacts && !loading ? (
        <CSVLink data={contacts}>
          {" "}
          <Button
            className="buttonClass"
            variant="primary"
            style={{
              display: "block",
              margin: "0 auto",
              width: "14em",
              marginTop: "2em",
            }}
          >
            Download Xero Contacts
          </Button>
        </CSVLink>
      ) : null}
    </Container>
  );
};

export default Contacts;
