import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

const styles = theme => ({
  progress: {
    color: "#4885ED",
    margin: "auto",
    display: "block"
  }
});

function Spinner(props) {
  const { classes } = props;
  return (
    <div style={{ marginTop: "3em" }}>
      <CircularProgress className={classes.progress} size={60} />
    </div>
  );
}

Spinner.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Spinner);
