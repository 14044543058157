import axios from "axios";
import {
  DELETE_INVOICE_PAYMENT,
  DELETE_INVOICE_PAYMENTS,
  GET_XERO_INVOICES,
  INVOICES_PAYMENTS_LOADING,
  POST_INVOICES_PAYMENTS,
} from "../redux/types";

export const getXeroInvoices = () => (dispatch) => {
  dispatch(setInvoicesPaymentsLoading());

  const getXeroInvoices = async () => {
    try {
      // RETURN 500 ERROR IF NO CURRENT ORG IS SELECTED
      if (
        localStorage.getItem("currentOrg") === undefined ||
        localStorage.getItem("currentOrg") === null ||
        localStorage.getItem("currentOrg").length === 0
      ) {
        dispatch({
          type: GET_XERO_INVOICES,
          payload: 500,
        });
        return;
      }

      // Obtain Xero keys from profile to send with invoice data
      let profileData = await axios.get("/api/profile");
      let tokenSet = await localStorage.getItem('tokenSet');
      let refresh = await localStorage.getItem('xrt');
      let currentTenant = await localStorage.getItem('currentOrg');
      let data = { ...JSON.parse(tokenSet), ...JSON.parse(currentTenant), refresh }

      let workbookUserName = profileData.data.workbookUserName;
      let workbookPassword = profileData.data.workbookPassword;
      let workbookURL = profileData.data.workbookURL;

      let toPost = { workbookUserName, workbookPassword, workbookURL, data };
      // Send invoice data
      let response = await axios.post("/api/get-payments", toPost);

      dispatch({
        type: GET_XERO_INVOICES,
        payload: response.data,
      });

    } catch (err) {
      if (err.response.status === 401) {
        dispatch({
          type: GET_XERO_INVOICES,
          payload: 401,
        });
      }
      else if (err.response.data === "WB connection error") {
        dispatch({
          type: GET_XERO_INVOICES,
          payload: "WB connection error",
        });
      }
      else {
        dispatch({
          type: GET_XERO_INVOICES,
          payload: 500,
        });
      }
    }
  }
  getXeroInvoices();
};

export const deleteXeroInvoice = (Id) => (dispatch) => {
  dispatch({
    type: DELETE_INVOICE_PAYMENT,
    payload: Id,
  });
};

// Delete Invoices
export const deleteXeroInvoices = (invoices) => (dispatch) => {
  dispatch({
    type: DELETE_INVOICE_PAYMENTS,
    payload: invoices,
  });
};

//Post Invoices
export const postInvoices = (invoices) => (dispatch) => {
  dispatch({
    type: POST_INVOICES_PAYMENTS,
    payload: invoices,
  });
};

// Invoices loading
export const setInvoicesPaymentsLoading = () => {
  return {
    type: INVOICES_PAYMENTS_LOADING,
  };
};
