export default (state, action) => {
    switch (action.type) {
      case "GET_CONTACTS":
        return {
          ...state,
          loading: false,
          contacts: action.payload,
        };
      default:
        return state;
    }
  };
  